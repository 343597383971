import {
    List,
    Datagrid,
    ArrayField,
    TextField,
    EditButton,
    DeleteButton,
    Show,
    SimpleShowLayout,
    Edit,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    Create,
    ReferenceField,
    ReferenceInput,
    BooleanField,
    BooleanInput,
    SelectInput,
    required,
    BulkDeleteButton,
} from 'react-admin';
import UrlField from '../components/UrlField';
import MlWrap from '../components/MlWrap';
import FileUploadInput from '../components/FileUploadInput';

const inspirationFilters = [
    <TextInput source="q" label="Search" resettable alwaysOn />,
    <ReferenceInput source="category_id" reference="categories" label="Category" resettable >
        <SelectInput optionText="caption" xs={{minWidth: '20rem'}} />
    </ReferenceInput>,
    <SelectInput
        source="active"
        choices={[
            {id: true, name: 'Active'},
            {id: false, name: 'Inactive'},
        ]}
        label="Status"
        resettable
    />
];

export const InspirationList = () => {
    return (
        <List filters={inspirationFilters}>
            <Datagrid
                rowClick="show"
                bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
            >
                <TextField source="id" />
                <ReferenceField source="category_id" reference="categories" />
                <MlWrap label="Title" lineClamp={2}>
                    <TextField source="title" />
                </MlWrap>
                <MlWrap Label="Body" lineClamp={2}>
                    <TextField source="body" />
                </MlWrap>
                <BooleanField source="active" />
                <EditButton />
                <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
        </List>
    );
};

export const InspirationShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="category_id" reference="categories" />
            <TextField source="title" />
            <TextField source="body" />
            <ArrayField source="assets">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" />
                    <UrlField source="url" />
                </Datagrid>
            </ArrayField>
            <BooleanField source="active" />
        </SimpleShowLayout>
    </Show>
);

export const InspirationEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" disabled />
            <ReferenceInput source="category_id" reference="categories">
                <SelectInput optionText="caption" validate={required()} fullWidth />
            </ReferenceInput>
            <TextInput source="title" fullWidth />
            <TextInput source="body" multiline minRows={5} maxRows={10} fullWidth />
            <ArrayInput source="assets">
                <SimpleFormIterator
                    inline
                    sx={{'& .RaSimpleFormIterator-inline': {flexGrow: 1, }}}
                >
                    <SelectInput
                        source="type"
                        choices={[
                            {id: 'image', name: 'Image'},
                            {id: 'audio', name: 'Audio'},
                        ]}
                        label="Type"
                        validate={required()}
                    />
                    <FileUploadInput source="url" />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Edit>
);

const inspirationDefaultValue = () => ({assets: [{type: 'image', url: ""}, {type: 'audio', url: ""}]});
export const InspirationCreate = () => (
    <Create>
        <SimpleForm defaultValues={inspirationDefaultValue}>
            <ReferenceInput source="category_id" reference="categories">
                <SelectInput optionText="caption" validate={required()} fullWidth />
            </ReferenceInput>
            <TextInput source="title" fullWidth />
            <TextInput source="body" multiline minRows={3} maxRows={10} fullWidth />
            <ArrayInput source="assets">
                <SimpleFormIterator
                    inline
                    sx={{'& .RaSimpleFormIterator-inline': {flexGrow: 1, }}}
                >
                    <SelectInput
                        source="type"
                        choices={[
                            {id: 'image', name: 'Image'},
                            {id: 'audio', name: 'Audio'},
                        ]}
                        label="Type"
                        validate={required()}
                    />
                    <FileUploadInput source="url" />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Create>
);