import {Typography} from '@mui/material';

const MlBox = ({lineClamp, children}) => {
    return <Typography
        sx={{
            display: '-webkit-box',
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        }}
    >
        {children}
    </Typography>;
};

export default MlBox;