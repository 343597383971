import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    Show,
    SimpleShowLayout,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    BulkDeleteButton,
} from 'react-admin';

export const CategoryList = () => {
    return (
        <List>
            <Datagrid
                rowClick="show"
                bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
            >
                <TextField source="id" />
                <TextField source="caption" />
                <EditButton />
                <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
        </List>
    );
};

export const CategoryShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="caption" />
        </SimpleShowLayout>
    </Show>
);

export const CategoryEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="caption" fullWidth />
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="caption" fullWidth />
        </SimpleForm>
    </Create>
);