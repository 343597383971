import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    EditButton,
    Show,
    SimpleShowLayout,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    BooleanInput,
    // ReferenceInput,
    SelectInput,
} from 'react-admin';

const userFilters = [
    // <ReferenceInput source="email" reference="users" label="Email" >
    //     <SelectInput optionText="email" xs={{minWidth: '20rem'}} />
    // </ReferenceInput>,
    // <TextInput source="email" />,
    <TextInput source="q" label="Search" resettable alwaysOn />,
    <SelectInput
        source="banned"
        choices={[
            {id: true, name: 'Banned'},
            {id: false, name: 'Allowed'},
        ]}
        label="Status"
        resettable
    />
];

export const UserList = () => (
    <List
        filters={userFilters}
        sort={{field: 'id', order: 'ASC'}}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="email" />
            <DateField source="registration_date" />
            <DateField source="activity_date" />
            <TextField source="main_profile" />
            <BooleanField source="banned" />
            <DateField source="subscription_date" />
            <TextField source="subscription_type" />
            <EditButton />
        </Datagrid>
    </List>
);

export const UserShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="email" />
            <DateField source="registration_date" />
            <DateField source="activity_date" />
            <TextField source="main_profile" />
            <BooleanField source="banned" />
            <DateField source="subscription_date" />
            <TextField source="subscription_type" />
        </SimpleShowLayout>
    </Show>
);

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" disabled fullWidth />
            <TextInput source="email" disabled fullWidth />
            <DateInput source="registration_date" disabled fullWidth />
            <DateInput source="activity_date" disabled fullWidth />
            <TextInput source="main_profile" disabled fullWidth />
            <BooleanInput source="banned" />
            <DateInput source="subscription_date" fullWidth />
            <TextInput source="subscription_type" fullWidth />
        </SimpleForm>
    </Edit>
);