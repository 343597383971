import {
    TextInput,
    FileInput,
    required,
} from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useFormContext} from 'react-hook-form';
import {useState} from 'react';

const uploadFile = (file) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const url = 'http://54.193.184.196:8803/api/v1/s3/?session_id=' + auth.session_id;
    const formData = new FormData();
    formData.append('files', file);
    return fetch(url, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch((error) => {
            throw error;
        });
};

const FileUploadInput = ({source}) => {

    const [isLoading, setIsLoading] = useState(false);
    const formContext = useFormContext();

    const fileChangeHandler = (file) => {
        setIsLoading(true);
        uploadFile(file)
            .then(data => {
                const {files: [{url}]} = data;
                formContext.setValue(source, url);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <TextInput source={source} sx={{flexGrow: 1}} validate={required()} />
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <FileInput source="" onChange={fileChangeHandler} />
                {isLoading && <CircularProgress sx={{m: 1}} />}
            </Box>
        </>
    );
};

export default FileUploadInput;