import {
    fetchUtils,
    combineDataProviders,
} from 'react-admin';
import jsonServerProvider from "ra-data-json-server";

const fetchJson = (url, options = {}) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const u = new URL(url);
    u.searchParams.set('session_id', auth.session_id);
    url = u.toString();
    return fetchUtils.fetchJson(url, options);
};

const dataProviderAuth = jsonServerProvider('http://54.193.184.196:8801/api/v1/admin', fetchJson);
const dataProviderProfile = jsonServerProvider('http://54.193.184.196:8802/api/v1/admin', fetchJson);
const dataProviderComposer = jsonServerProvider('http://54.193.184.196:8804/api/v1/admin', fetchJson);

export const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'users':
            return dataProviderAuth;
        case 'profiles':
            return dataProviderProfile;
        case 'inspirations':
        case 'categories':
            return dataProviderComposer;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});