import {useRecordContext} from "react-admin";
import {Link} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href,
    }).click();
};

const UrlField = ({source}) => {
    const record = useRecordContext();
    return record && record[source] ? (
        <Link href={record[source]} target='_blank' sx={{textDecoration: 'none', whiteSpace: 'normal'}} onClick={e => {openInNewTab(record[source]); e.preventDefault()}}>
            <LaunchIcon sx={{fontSize: 15, mr: 1}} />
            {record[source]}
        </Link>
    ) : null;
};

export default UrlField;