import {
    useRecordContext,
} from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
    useState,
    useEffect,
} from 'react';

const fetchFileUrlByUuid = (fileUuid) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const url = 'http://54.193.184.196:8803/api/v1/s3/' + fileUuid + '/url?session_id=' + auth.session_id;
    return fetch(url)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(url => {
            return url;
        })
        .catch((error) => {
            throw error;
        });
};

const S3ImageField = ({source, fallback, sx = []}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const record = useRecordContext();
    const fileUuid = record && record[source];

    useEffect(() => {
        setFileUrl(null);
        if (!fileUuid) {
            return;
        }
        setIsLoading(true);
        fetchFileUrlByUuid(fileUuid)
            .then(url => {
                setFileUrl(url)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, [fileUuid]);

    return (
        <Box
            sx={[
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                {
                    '> *': {
                        objectFit: 'contain',
                        height: '100%',
                        width: '100%',
                    }
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                fileUrl ? (
                    <img src={fileUrl} alt="" />
                ) : (
                    fallback
                )
            )}
        </Box>
    );

};

export default S3ImageField;