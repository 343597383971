const authApiUrl = 'http://54.193.184.196:8801/api/v1';

export const authProvider = {
    login: ({username, password}) => {
        const request = new Request(authApiUrl + '/user/authenticate', {
            method: 'POST',
            body: JSON.stringify({email: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                console.log(auth);
                if (!auth.success) {
                    throw new Error(auth.reason);
                }
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
    logout: () => {
        try {
            const auth = JSON.parse(localStorage.getItem('auth'));
            localStorage.removeItem('auth');
            if (auth.session_id) {
                const request = new Request(authApiUrl + '/sessions/' + auth.session_id, {method: 'DELETE'});
                return fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log(data);
                        return Promise.resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        throw error;
                    });
            }
        } catch (error) {
            console.log(error);
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const auth = JSON.parse(localStorage.getItem('auth'));
            if (!auth) {
                return Promise.reject('Failed user identity');
            }
            if (!auth.user_id) {
                return fetch(authApiUrl + '/sessions/' + auth.session_id + '/user')
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log(data);
                        Object.assign(auth, data);
                        localStorage.setItem('auth', JSON.stringify(auth));
                        return Promise.resolve({id: auth.user_id, fullName: auth.nickname});
                    })
                    .catch((error) => {
                        console.log(error);
                        throw error;
                    });
            } else {
                return Promise.resolve({id: auth.user_id, fullName: auth.nickname});
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;