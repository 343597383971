import {
    Admin,
    Resource,
} from 'react-admin';
import {dataProvider} from './providers/dataProvider';
import {authProvider} from './providers/authProvider';
import {Dashboard} from './views/Dashboard';
import {
    InspirationList,
    InspirationShow,
    InspirationEdit,
    InspirationCreate
} from './resources/inspirations';
import {
    CategoryList,
    CategoryShow,
    CategoryEdit,
    CategoryCreate
} from './resources/categories';
import {
    UserList,
    UserShow,
    UserEdit
} from './resources/users';
import {
    ProfileList,
    ProfileShow
} from './resources/profiles';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import UserIcon from '@mui/icons-material/Group';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

export const App = () =>
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
    >
        <Resource name="inspirations"
            list={InspirationList}
            show={InspirationShow}
            edit={InspirationEdit}
            create={InspirationCreate}
            icon={ViewCarouselIcon}
        />
        <Resource name="categories"
            list={CategoryList}
            show={CategoryShow}
            edit={CategoryEdit}
            create={CategoryCreate}
            recordRepresentation="caption"
            icon={DynamicFeedIcon}
        />
        <Resource name="users"
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            recordRepresentation="email"
            icon={UserIcon}
        />
        <Resource name="profiles"
            list={ProfileList}
            show={ProfileShow}
            icon={SwitchAccountIcon}
        />
    </Admin>;

export default App;