import {
    List,
    Datagrid,
    TextField,
    NumberField,
    BooleanField,
    ReferenceField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import S3ImageField from '../components/S3ImageField';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export const ProfileList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />
            {/* <TextField source="uuid" /> */}
            <ReferenceField source="creator_id" reference="users" />
            <ReferenceField source="maintainer_id" reference="users" />
            <TextField source="username" />
            <TextField source="spaces" />
            <S3ImageField
                source="image"
                fallback={<AccountBoxIcon sx={{color: '#888', fontSize: '28px'}} />}
                sx={{height: '28px'}}
            />
            <BooleanField source="base_profile" />
            <NumberField source="post_count" />
            <NumberField source="mention_count" />
        </Datagrid>
    </List>
);

export const ProfileShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="uuid" fullWidth />
            <ReferenceField source="creator_id" reference="users" />
            <ReferenceField source="maintainer_id" reference="users" />
            <TextField source="username" fullWidth />
            <TextField source="spaces" fullWidth />
            <S3ImageField
                source="image"
                fallback={<AccountBoxIcon sx={{color: '#888', fontSize: '200px'}} />}
                sx={{height: '250px'}}
            />
            <BooleanField source="base_profile" />
            <NumberField source="post_count" />
            <NumberField source="mention_count" />
        </SimpleShowLayout>
    </Show>
);